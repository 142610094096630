import React, { useState } from "react";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

import axiosInstance from "../axiosInstance";
import Button from "../components/formComponents/Button";
import FileUpload from "../components/FileUpload";
import useRefreshToken from "../hooks/useRefreshToken";
import useUploadWarning from "../hooks/useUploadWarning";

const UploadForm = ({
  project,
  fetchData,
  setSelectedCategory,
  handleClose,
}) => {
  const [file, setFile] = useState([]);
  const [error, setError] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingFile, setLoadingFile] = useState(false);
  const refreshToken = useRefreshToken();
  const onSubmit = async () => {
    setLoadingFile(true);
    setUploadProgress(0);
    setError(file.length === 0);
    if (!error) {
      const fileArray = Array.from(file);
      const compressedImages = [];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      let previousUploadedBytes = 0;
      let totalUploadedImages = 0;

      for (let i = 0; i < fileArray.length; i++) {
        let oneFile = fileArray[i];
        if (oneFile.name.toUpperCase().includes("_V.")) {
          const compressedFile = await imageCompression(oneFile, options);
          const renamedCompressedFile = new File(
            [compressedFile],
            oneFile.name,
            {
              type: compressedFile.type,
              lastModified: Date.now(),
            },
          );
          oneFile = renamedCompressedFile;
        }
        compressedImages.push(oneFile);
      }
      const totalBytes = compressedImages.reduce(
        (acc, curr) => acc + curr.size,
        0,
      );
      const updateProgress = (progressEvent) => {
        const chunkProgress = progressEvent.loaded;
        const totalUploadedBytes = previousUploadedBytes + chunkProgress;

        const percentCompleted = Math.min(
          Math.round((totalUploadedBytes / totalBytes) * 100),
          100,
        );
        setUploadProgress(percentCompleted);
      };

      const uploadFiles = async (fileChunk) => {
        const formData = new FormData();
        fileChunk.forEach((file) => formData.append("files", file));
        const properties = {
          id: project?.id,
          drone: project?.drone ? true : false,
        };
        formData.append("properties", JSON.stringify(properties));
        try {
          await axiosInstance.postData("/projects/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => updateProgress(progressEvent),
          });
          if (totalUploadedImages % 100 === 0) {
            refreshToken();
          }
          totalUploadedImages += fileChunk.length;
          const chunkBytes = fileChunk.reduce(
            (acc, file) => acc + file.size,
            0,
          );
          previousUploadedBytes += chunkBytes;
        } catch (err) {
          throw err;
        }
      };

      for (let i = 0; i < compressedImages.length; i += 10) {
        const fileChunk = compressedImages.slice(i, i + 10);
        await uploadFiles(fileChunk);
      }

      setFile([]);
      setSelectedCategory("In Process");
      fetchData(1, true, true);
      setLoadingFile(false);
      if (handleClose) {
        handleClose();
      }
      toast.success("Files uploaded successfully!");
    }
  };

  useUploadWarning(loadingFile);

  return (
    <form className="flex flex-col items-center w-full py-4 md:py-10">
      <FileUpload
        file={file}
        setFile={setFile}
        error={error}
        uploadProgress={uploadProgress}
        loading={loadingFile}
      />
      <Button
        type="submit"
        text="Upload"
        disabled={loadingFile || file.length === 0}
        className="rounded-full px-6"
        onClick={onSubmit}
      />
    </form>
  );
};

export default UploadForm;
