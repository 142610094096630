import { useState, useEffect } from "react";
import { CheckoutForm } from "../../components/Payments";
import ModalLayout from "../../components/ModalLayout";
import Button from "../../components/formComponents/Button";
import axiosInstance from "../../axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import { updateWallet } from "../../Redux/features/user/userSlice";

const Credits = () => {
  const credits = useSelector((state) => state.userReducer.wallet.credits);
  const [clickToPay, setClickToPay] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchPackages();
    fetchTransactions();
    fetchCredits();
  }, []);
  const fetchCredits = async () => {
    const response = await axiosInstance.getData("/wallet/me");
    dispatch(updateWallet(response?.data));
  };
  const fetchPackages = async () => {
    const response = await axiosInstance.getData("/packages");
    setOffers(response);
  };

  const fetchTransactions = async () => {
    const response = await axiosInstance.getData("/payments/me");
    setTransactionHistory(response.data);
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setClickToPay(true);
  };

  return (
    <div className="px-4 w-full lg:flex justify-center items-start">
      <ModalLayout
        open={clickToPay}
        handleClose={() => setClickToPay((prev) => !prev)}
        className=" w-full md:w-[70%] !bg-primary "
      >
        <CheckoutForm offer={selectedOffer} />{" "}
      </ModalLayout>
      <div className=" xl:w-[65%]">
        <div className="py-10 space-y-8">
          <div className="text-4xl font-bold text-white">Credits</div>
          <div className="bg-primary p-6 rounded-lg text-center border-[8px] border-gray-700">
            <div
              className="text-2xl font-semibold border-hidden text-white animate-pulse"
              style={{
                animationDuration: "1.5s",
              }}
            >
              Available Credits
            </div>

            <div
              className="text-6xl border-hidden font-bold text-[#FC620A] animate-pulse"
              style={{
                animationDuration: "1.5s",
                animationTimingFunction: "ease-in-out",
              }}
            >
              {credits}
            </div>

            <div className="text-sm text-gray-400">
              You can use your credits to make panel inspection.
            </div>
          </div>
        </div>

        <div className=" md:flex flex-grow gap-4">
          {offers?.map((offer) => (
            <div
              key={offer.id}
              className="bg-primary w-full my-4 p-6 rounded-lg shadow-lg text-center flex flex-col border border-gray-700 hover:border-[#FC620A] group transition-all"
            >
              <div className="text-3xl font-bold text-white group-hover:text-[#FC620A]">
                {offer.credits} Credits
              </div>
              <div className="text-xl text-gray-200 mt-2">${offer.price}</div>
              <p className="text-xl text-white mt-2">{offer.name}</p>
              <Button
                className="mt-4 px-4 py-2 rounded-md text-lg font-medium text-white"
                onClick={() => handleOfferClick(offer)}
                text={"Buy Now"}
              ></Button>
            </div>
          ))}
        </div>

        <div className="text-lg text-gray-300 space-y-3 pt-6">
          <div>
            <strong className="text-[#FC620A]">Buy More Credits: </strong>
            Buy credits and use them for future inspection!
          </div>
          <div>
            <strong className="text-[#FC620A]">Use Credits: </strong>
            Use your credits to unlock premium features or services.
          </div>
        </div>
      </div>
      <div className=" lg:px-10 xl:w-[35%]">
        <div className="text-xl pt-10 md:pt-4 lg:pt-0 font-bold text-white mb-4">
          Transaction History
        </div>
        <div className="h-[670px] overflow-auto">
          <table className="w-full table-auto rounded-md pb-4 border-separate border-spacing-0">
            <thead className="sticky top-0 bg-primary z-10">
              <tr className="text-left border-b border-gray-400">
                <th className="py-3 px-4 text-white">Credits</th>
                <th className="py-3 px-4 text-white">Status</th>
                <th className="py-3 px-4 text-white">Date</th>
              </tr>
            </thead>
            <tbody>
              {transactionHistory?.map((transaction, index) => (
                <tr
                  key={index}
                  className="text-gray-300 border-b border-gray-700 hover:bg-[#d48659] hover:text-white transition-all"
                >
                  <td className="py-3 px-4">{transaction.credits}</td>
                  <td
                    className={`py-3 capitalize px-4 ${
                      transaction.status === "success"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {transaction.status}
                  </td>
                  <td className="py-3 px-4">
                    {new Date(transaction.createdAt).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Credits;
