import { useState } from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowButton from "../assets/icons/ArrowButton";
import ImageColorCategory from "./ImageColorCategory";
import ModalImage from "./ModalImage";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";
import ConfirmModal from "./ConfirmModal";

function ImageInfiniteScroll({
  fetchData,
  items = [],
  setItems,
  hasMore,
  setImage,
  error,
  imageSelect,
  imageRefs,
  showImage,
  loading,
  handleImageClick,
  handleFixes,
  image,
}) {
  const [id, setId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [update, setUpdate] = useState(false);

  const { reset } = useForm();
  const handleCloseDelete = (id) => {
    if (id) {
      setId(id);
    }
    reset();
    setOpenDelete(!openDelete);
  };

  const deleteImage = () => {
    axiosInstance
      .deleteData(`projects/images/${id}`)
      .then((res) => {
        if (res.data) {
          toast.success("Image deleted successfully");
          setUpdate(!update);
        } else {
          throw new Error("Failed to delete image");
        }
      })
      .catch((err) => {
        console.error("Delete error:", err);
      })
      .finally(() => {
        setItems(items.filter((item) => item.id !== id));
        handleCloseDelete();
      });
  };

  return (
    <div>
      <ConfirmModal
        title={"Are you sure you want to delete this Image?"}
        handleClose={handleCloseDelete}
        open={openDelete}
        onSubmit={deleteImage}
        loading={loading}
        buttonText={"Delete"}
      />
      <InfiniteScroll
        dataLength={items?.length}
        next={fetchData}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center animate-[bounce_1.5s_ease-in-out_infinite] py-8">
            <p className="text-xl flex justify-center items-center  rotate-90 w-fit rounded-full">
              <ArrowButton />
            </p>
          </div>
        }
        endMessage={
          <div className="flex justify-center py-8">
            <p className="text-xl flex justify-center items-center py-4 px-4 lg:px-32 border border-purple text-white w-fit rounded-xl">
              No more pictures to load!
            </p>
          </div>
        }
      >
        <div className="grid grid-cols-2  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-2 gap-4">
          {items?.map((item, index) => (
            <div
              ref={(el) => (imageRefs.current[index] = el)}
              className={`relative border-2 rounded-lg group/delete  transform transition-all duration-300 ${
                imageSelect === item.id
                  ? "border-red-500 animate-pulse scale-110"
                  : "border-transparent"
              }`}
              key={index}
            >
              <div className="relative h-20 xl:h-28 w-full bg-cover rounded-lg overflow-hidden">
                <ImageColorCategory
                  item={item}
                  handleCloseDelete={handleCloseDelete}
                />

                <img
                  onClick={() => handleImageClick(item)}
                  className="h-20 xl:h-28 w-full bg-cover rounded-lg"
                  src={item?.image}
                  alt={item?.id}
                />
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
      <ModalImage
        item={image}
        images={items}
        setImage={setImage}
        isLoading={loading}
        show={showImage}
        setShow={handleImageClick}
        handleFixes={handleFixes}
        handleImageClick={handleImageClick}
      />
      {error && <p>Error: {error.message}</p>}
    </div>
  );
}

export default ImageInfiniteScroll;
