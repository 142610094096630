import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import expirationReducer from "../features/tokenExpiration/expirationSlice";
import clientsReducer from "../features/clients/clientsDataSlice";
import isAuthorizedReducer from "../features/authorization/authorizationSlice";
import userReducer from "../features/user/userSlice";
const rootReducer = combineReducers({
  expirationReducer,
  clientsReducer,
  isAuthorizedReducer,
  userReducer,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["isAuthorizedReducer", "userReducer"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
