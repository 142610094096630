import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axiosInstance from "../axiosInstance";
import { useDispatch } from "react-redux";
import { updateWallet } from "../Redux/features/user/userSlice";

const stripePromise = loadStripe(
  "pk_test_51Q8JVFBLKk8vFEIfa1nc56kjki6LSLyTN1XktrLcFBfn4rXY9kuIJFTykMIZmF2qrdjdam8KsbqN2DSKOwGlO3Vt00ltO50dUk",
);

export const CheckoutForm = ({ offer }) => {
  const fetchClientSecret = useCallback(() => {
    return axiosInstance
      .postData("/stripe/create-checkout-session", {
        packagedId: offer.id,
        price: offer.price,
        name: offer.name,
        description: offer.description,
      })
      .then((data) => data.clientSecret);
  }, []);
  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="w-full  border-2 border-white">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [wallet, setWallet] = useState(null); // Added to store wallet data
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");
  const packagedId = urlParams.get("packagedId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      axiosInstance
        .postData(`/stripe/verify-payment`, {
          sessionId,
          packageId: packagedId,
        })
        .then((response) => {
          if (response.session.status === "complete") {
            window.location.href = "/admin/credits";
          }
          setStatus(response.session.status);
          setCustomerEmail(response.session.customer_email);
          setWallet(response.wallet); // Set wallet data from response
          dispatch(updateWallet(response.wallet));
        })
        .catch((error) => {
          console.error("Error fetching session status:", error);
        });
    }
  }, [sessionId]);

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email{" "}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
        <p>Your wallet has been updated with {wallet.credits} credits.</p>
      </section>
    );
  }
  return null;
};
